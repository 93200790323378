#mydiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.create_profile {
    background-color: #0480fc;
    border: none;
    outline: none;
    width: 350px;
    height: 70px;
    border-radius: 10px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bolder;
}