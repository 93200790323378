.name_style {
    display: flex;
    color: red;
}


.username {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 700;
    color: var(--primary);
    font-size: 20px;
    margin-left: 1.7rem;
  }
  .user_name {
    color: var(--text-dark);
    font-weight: 400;
    font-size: 11px !important;
    letter-spacing: -0.02em;
  }

  .new_jumbotron {
    width: 100% !important;
    height: auto;
    padding: 2rem;
    background: var(--primary);
    border-radius: 12px;
    font-family: "Source Sans Pro", sans-serif !important;
    margin: auto;
  
  }
  .ward_container {
    width: 96%;
    height: auto;
    margin: 2rem auto;
  }
  .new_jumbotron h2 {
    font-weight: 700;
    color: var(--text-white);
    font-size: 30px;
  }
  .new_jumbotron p {
    font-weight: 400;
    color: var(--text-white);
    font-size: 16px;
    padding: 2rem auto;
  }

  .btn-application {
    font-size: 18px;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif !important;
    background: var(--btn-default-bg) !important;
    color: var(--primary);
    border: none !important;
    outline: none !important;
  }


  .ward_card {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.07);
    border-radius: 12px;
    padding: .5rem 1rem;
  }


  .ward_card h4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -0.02em;
    color: var(--text-dark);
  }
  .ward_card p {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;    
    color: var(--text-dark);
  }


  .number_student {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .number_student .header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;    
    color: #007EFF;
  }

  .number_student .header_number{
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    
    color: #76787A;
  }

  .student_detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .student_name {
      display: flex;
      align-items: center;
      font-family: Samo Sans Pro;
  }

  .student_name span{
      margin-left: .5rem;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: var(--text-dark)
  }


  .student_detail .profile__link button{
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #2F80ED;
    background: transparent;
    border: none;
    outline: none;
  }


  .container-simulator {
    width: 96%;
    height: auto;
    margin: 2rem auto;
  }

  .send_reminder {
    background: #F5F5F5;
    border-radius: 10px;
    padding: .5rem 1rem;
    border: none;
  }

  .career_image {
       display: flex;
       width: 100%;
       justify-content: space-between;
       align-items: center;
  }
  .career_image button {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #828282;
  }

  .career_image  img {
      width: 80px;
      height: 80px;
      background: var(--bg-icons);
      border-radius: 50%;
  }

  h3.career_sim_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: var(--primary) !important;
    
  }

  p.career_sim_text {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #4D5E70;
  } 

  .applicant_table {
    width: 96%;
    height: auto;
    margin: 2rem auto;
    border-radius: 12px;
  }


  .applicant_table .card__title span:first-child {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: var(--primary);
  }

  .essential_new_simulator {
    background: var(--bg-white);
    padding: 1rem;
    padding-bottom: 3rem;
    border-radius: 12px;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif !important;
  }

  .essential_new_simulator h3 {
    font-weight: bold;
    color: var(--text-dark);
    font-size: 32px;
  }
  
  .essential_new_simulator p {
    font-weight: 400;
    font-size: 18px;
    color: var(--text-grey);
  }
  .essential_new_simulator:nth-child(n + 2) {
    margin-left: 2rem;
  }

  .career_start_simulate_all {
      margin-left: 1rem !important;
  }

  .card__title {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      padding: 1rem 1rem 0 1rem;
  }

  .school__img img {
      width: 50px;
      height: 50px;
      background: var(--bg-white);
      border: 1px solid #E0E0E0;
      text-align: center;
      border-radius: 30px;
  }

  .school__img span {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #011A32;
  }

  .align_student_table {
      vertical-align: middle !important;
  }

  .tborder {
      margin-bottom: 1rem !important;
  }

  .tborder tr.align_student_table  {
      border-bottom: 1px solid #EEEEEE !important;
  }


  .tborder tr.align_student_table  th {
        border: none !important;
        font-family: Samo Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #4D5E70 !important;
  }

  .align_student_table td {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px; 
    color: #4D5E70 !important;
  }

  .ongoing_status span{
    padding: 5px 12px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;

    color: #FF922D;
    background: #FFF4EB !important;
    border-radius: 6px;
  }

  .rejected_status span {
    padding: 5px 12px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    background: #FFEBEB;
    border-radius: 6px;
    color: #FF2D2D;
  }

  .accepted_status span {
    padding: 5px 12px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    background: #ECFFF0;
    border-radius: 6px;
    color: #1ECC02;
  }

  .parent_view_more a {
        font-family: Samo Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        text-decoration-line: underline;    
        color: #011A32;
  }

  .parent_details_row {
      width: 98%;
      margin: 2rem auto;
  }

.parent_career_card {
    background: var(--bg-white);
    border-radius: 12px;
    position: relative;
}
.parent_career_simulate {
    width: 100%;
    padding: 2rem 1rem;
}
.parent_career_title{
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--primary);
}

.parent_result_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--btn-secondary-bg);
}

.parent_realistic_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: var(--success)
}

.parent_career_text {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--btn-secondary-bg);
    width: 60%;
}

.parent_career_recommendations {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline;
    color: var(--primary);
    text-decoration: underline;
    border: none;
    outline: none;
    background: none;
}

.parent_bg_career_img{
    height: 180px;
    width: 45%;
    position: absolute;
    bottom: 0px;
    right: 1px;
}

.parent_career_bag {
    position: absolute;;
    width: 80px;
    height: 80px;
    background: #e8f5ff;
    border-radius: 50%;
    right: 15%;
    bottom: 24%;
}

.parent_career_bag img {
    width: 100%;
    height: 100%;
}

.country_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12.2357px;
    line-height: 15px;
    color: #333333;

}

.country_detail {
    width: 70%;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* Gray 1 */

    color: #333333;
}

.parent_page  {
    width: 98%;
}

.number_of_ward {
     font-family: DM Sans;
     font-style: normal;
     font-weight: bold;
     font-size: 36px;
     line-height: 47px;
     color: #2C2C2C;
     margin: 5px 0px;
}

.number_of_ward_placeholder {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--primary);
  margin: 0px;
}

.parent_student {
    padding: 10px 16px 16px 16px;
    width: 242px;
    height: 130px;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 2rem 0;
}

.student_parent_detail {
    padding-bottom: 15rem;
    padding-top: 1rem;
    width: 98%;
}

.student_parent_card_view {
  background: #FFFFFF;
  border-radius: 10.8462px;
}

.card_view_header {
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 32.5385px;
  line-height: 40px;
  color: #4D5E70;
}

.card_view_text {
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12.6538px;
  line-height: 140%;
  color: #333333;
}

.btn_actions {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-top: 1rem;
     padding-bottom: .5rem;
}

.card_view_profile {
    border: none;
    outline: none;
    background: transparent;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4615px;
    line-height: 18px;
    color: #075CB2;
}

.card_view_application {
    background: #FFFFFF;
    border: 0.903846px solid #2F80ED;
    box-sizing: border-box;
    border-radius: 7.23077px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4615px;
    line-height: 18px;
    text-align: center;
    color: #2F80ED;
    padding: .5rem .85rem;
}

.card_view_simulation {
    background: #2F80ED;
    border: 0.903846px solid #2F80ED;
    border-radius: 7.23077px;
    padding: .5rem .85rem;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4615px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.secondary_nav {
    width: 96%;
    margin: auto;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.students_details_name {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 1px;

    color: var(--text-dark);
}
.students_school_detail {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */
    
    letter-spacing: 0.3px;
    
    color: var(--text-dark);
}
.students_actions_btn button:first-child {
       margin-right: .8rem;
       background: var(--primary);
       backdrop-filter: blur(24px);
       border-radius: 8px;
       font-family: Samo Sans Pro;
       font-style: normal;
       font-weight: bold;
       font-size: 16px;
       line-height: 20px;
       letter-spacing: 0.02em;
       padding: .5rem 1rem;
       border: 1px solid var(--primary);
       color: var(--text-white)
}

.students_actions_btn .view_college_simulations {
  margin-right: .8rem;
  background: rgb(90, 11, 104);
  backdrop-filter: blur(24px);
  border-radius: 8px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding: .5rem 1rem;
  border: 1px solid rgb(90, 11, 104);

  color: var(--text-white)
}

.students_actions_btn button:last-child{
    background: #8F90D1;
    backdrop-filter: blur(24px);
    /* Note: backdrop-filter has minimal browser support */
    
    border-radius: 8px;

    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: .5rem 1rem;
    color: var(--text-white);
    border: 1px solid #8F90D1;
}

.students_details_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card_personal_info {
    width: 98%;
    margin: 2.5rem auto;
    background: #FFFFFF;
    /* border: 1px solid #DFE0EB; */
    box-sizing: border-box;
    border-radius: 8px;
}

.card_document_info {
    width: 100%;
    margin: 2.5rem auto;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 8px;
}

.card_header {
    padding: 1rem 0 .5rem 2rem;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;

    color: #000000;
    
}
.card_personal_details {
   margin-bottom: 1rem;
}
.card_personal_details p {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    padding-left: 1.5rem;
    text-transform: uppercase;
    color: #828282;
}

.card_personal_details p > span {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    text-transform: initial;
    padding: 5px 0;
    margin: 5px 0;
    
    color: #252733;
}

.student_document {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 1rem 1.5rem 1rem 1rem;
    margin-left: 1.5rem;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 8px;
}

.student_doc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 1rem 1.5rem 1rem 1rem;
  margin-left: 1.5rem;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
}
.student_doc div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.student_doc .file_name{
  margin-left: .5rem;
}

.student_doc .file_logo {
   color: var(--primary);
   background:  #F7FBFF;
   border-radius: 20px;
   font-size: 16px;
   padding: .1rem;
}
.student_document div {
    display: flex;
    align-items: center;
}
.student_document .file_name{
    margin-left: .5rem;
}

.student_document .file_logo {
     color: var(--primary);
     background:  #F7FBFF;
     border-radius: 20px;
     font-size: 16px;
     padding: .1rem;
}
.file_time {
    color: #76787A;
    background:  #F7FBFF;
    border-radius: 20px;
    font-size: 16px;
    padding: .1rem;
    display: flex;
    
}
.doc-time {
  margin-left: 10px;
}
.student_document div > a {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    text-decoration-line: underline;
    color: var(--primary);
}
.student_document .file_name {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    
    color: var(--text-dark);
}

.student_document .file_time_name {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    
    color: var(--text-dark);
}

.badges_chat {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    margin-left: 1rem;
    border-radius: 28px;
    letter-spacing: 0.02em;
    
    color: #FFFFFF;
    background: #EB5757;
    padding: 2px 6px;
}

.student_doc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 1rem 1.5rem 1rem 1rem;
  width: 98%;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px auto !important;
}

.student_doc .file_name{
  margin-left: .5rem;
}

.student_doc .file_logo {
   color: var(--primary);
   background:  #F7FBFF;
   border-radius: 20px;
   font-size: 16px;
   padding: .1rem;
}


.file__name {
    width: 20%;
}


.doc_card_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 1rem 0 .5rem 2rem;
    color: #4D5E70;
}

.file_remove {
    cursor: pointer;
}

  /* Responsiveness from tablet upward */
@media screen and (min-width: 48em) {
    .username {
        font-size: 36px !important;
      }
      .user_name {
        font-size: 13px !important;
        margin-left: 9px;
      }

      .new_jumbotron h2 {
        font-size: 42px;
      }
      .new_jumbotron p {
        font-size: 18px;
      }
      .btn-application {
        margin-top: 4rem;
        padding: 0.5rem 1rem;
      }

      .essential_new_simulator {
          width: 48%;
      }
}