:root {
    --simulator_bg: #e5e5e5;
    --light-blue: #f7fbff;
    --light-blue-500: #97cafd;
    --light-white: #fbfbfb;
    --dark-blue: #011a32;
    --text-dark-grey: #4d5e70;
    --text-light: #d3d3d3;
    --skyblue: #edf4fc;
  }
  
  .simulator_wrapper {
    background: var(--simulator_bg);
    /* height: 100%; */
    width: 100%;
    padding: 1rem 0;
  }
  
  .choices_wrapper {
    background: var(--simulator_bg);
    /* height: 100%; */
    width: 100%;
    padding: 1rem 0;
  }
  
  .colleges_wrapper {
    background: #ffffff;
    width: 100%;
    padding: 1rem 0;
  
  }
  
  
  .back_navigate {
    color: #011A32;
    margin-top: 4rem;
    font-size: 16px;
  }
  .college_simulator {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
  }
  
  .college_simulator_content {
    padding: 5rem 0 6rem 0;
    width: 100%;
    height: auto;
  }
  
  .college_simulator_content h2 {
    line-height: 40px;
    font-weight: 700;
    color: var(--primary);
    letter-spacing: 4px;
  }
  
  .college_simulator_content p {
    font-weight: 400;
    font-size: 18px;
    color: var(--bg-dark);
    width: 100%;
  }
  .college_simulator_content a {
    background: var(--color-dark-600);
    outline: none;
    border: 1px solid var(--color-dark-600);
    color: var(--text-white);
    padding: 0.5rem 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    border-radius: 8px;
  }
  
  .image_part img {
    width: 100%;
    height: 100%;
  }
  
  .back_link {
    font-size: 12px;
    cursor: pointer !important;
    position: relative;
    z-index: 10000;
  
  }
  .name_link h3 {
    color: var(--primary) !important;
    font-weight: 700;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .form-select,
  .city-form {
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 0.7rem 0.7rem;
  }
  .form-select:focus,
  .form-select:active,
  .city-form:focus,
  .city-form:active {
    border: none !important;
    outline-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
  
  .form-select-lg,
  .city-form {
    color: var(--text-gray-800);
  }
  .choice_form1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 7rem;
    justify-content: center;
    align-items: center;
  }
  .questions_tag {
    font-weight: 400;
    font-size: 18px;
    color: var(--primary);
  }
  .choice_form1 h2 {
    font-weight: normal;
    text-align: center;
    color: var(--text-dark);
    margin-bottom: 3rem;
  }
  .form-control {
    padding: 0.7rem 0.7rem;
  }
  ul.list_tags {
    width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    list-style: none !important;
    font-size: 18px;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
  }
  ul.list_tags li {
    text-align: center !important;
    padding: 10px 18px;
    background: var(--bg-white);
    color: var(--text-gray-800);
    border-radius: 8px;
    margin-bottom: 15px;
  }
  ul.list_tags li:nth-child(n + 2) {
    margin-left: 0.5rem;
  }
  .delete_btn {
    font-size: 14px;
    cursor: pointer;
    color: var(--text-light);
  }
  .progress_bg {
    width: 100%;
    height: 70px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-white);
    border-radius: 12px;
    margin-top: 5rem;
  }
  .btnPrev button {
    width: 59px;
    height: 56px;
    background: var(--light-blue);
    border: none;
    outline: none;
    border-radius: 12px;
    color: var(--light-blue-500);
  }
  
  .btnNext button {
    width: 200px;
    height: 56px;
    border: none;
    background: var(--primary);
    color: var(--text-white);
    border-radius: 10px;
  }
  
  .icon {
    font-size: 20px;
  }
  .next_question {
    margin-right: 10px;
  }
  
  .ant-progress-line {
    width: 99%;
    margin-left: 0.3rem;
    margin-top: 1.5rem;
  }
  
  .ant-progress-inner {
    height: 2px;
  }
  
  .ant-slider-rail {
    height: 2px;
  }
  
  .ant-slider-track .ant-slider-track-1 {
    height: 2px;
  }
  .price-range {
    width: 100%;
    padding: 0.7rem;
    border-radius: 12px;
    background: var(--text-white);
  }
  .default-prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .price-def {
    color: var(--text-grey-800);
  }
  .price-value {
    display: inline;
    color: var(--text-grey-800);
  }
  .price-value span {
    color: var(--text-grey-800);
    font-size: 15px !important;
  }
  
  .results {
    width: 100%;
    height: 100%;
  }
  .result__wrapper {
    height: 100%;
    width: 100%;
  }
  .result__page {
    height: 100%;
    width: 100%;
    padding: 2rem 0px 4rem 0px;
    background: var(--simulator_bg);
  }
  .result_region {
    height: 100%;
    width: 100%;
    padding: 2rem 0px;
    background: var(--light-white);
  }
  .regions_result,
  .college_result {
    width: 100%;
    padding: 3rem;
  }
  .regions_result h6 {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: var(--btn-secondary-bg);
  }
  .results__city {
    margin-left: 10rem;
  }
  .results__city1 {
    margin-left: 1rem;
  }
  .region__header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }
  .recommended_college {
    height: 100%;
    width: 100%;
    padding: 2rem 0px 4rem 0px;
    background: var(--light-blue);
  }
  
  .recommended_col {
    box-shadow: 2px 2px 12px rgba(255, 255, 255, 0.3);
  }
  .recommended_col:nth-child(n + 2) {
    margin-top: 1rem;
  }
  .recommended_col .card {
    border: none !important;
  }
  .college_result h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    color: var(--primary);
  }
  
  .recommended_courses {
    height: 100%;
    width: 100%;
    padding: 2rem 0px 4rem 0px;
    background: var(--skyblue);
  }
  
  .available_scholarship {
    height: 100%;
    width: 100%;
    padding: 2rem 0px 4rem 0px;
    background: var(--light-white);
  }
  
  .success_result {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 7rem;
  }
  .cta-links {
    width: 100%;
    margin-top: 2rem;
    font-size: 10px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .cta-links a {
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }
  .btn_primary {
    color: var(--text-white);
    background: var(--primary);
    border: 1px solid var(--primary);
  }
  .btn_primary:hover {
    color: var(--text-white);
  }
  
  .btn_success {
    color: var(--text-white);
    background: var(--success);
    border: 1px solid var(--success);
  }
  .btn_success:hover {
    color: var(--text-white);
  }
  .btn_default {
    color: var(--text-grey);
    border: 1px solid var(--text-grey);
  }
  .btn_default:hover {
    color: var(--text-grey);
  }
  .username_message {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 45px;
    color: var(--text-dark);
    letter-spacing: -0.02em;
  }
  
  h2.success_message {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 79px;
    color: var(--success);
  }
  .success_item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem auto;
  }
  
  .success_item span {
      padding: .7rem 1.8rem;
      font-family: Samo Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      background: #F7FBFF;
      color: var(--primary);
      border-radius: 12px;
      text-align: center;
  }
  .success_item span:nth-child(n+2) {
      margin-left: 1.8rem;
  
  }
  .success_note {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--btn-secondary-bg);
  }
  .success_note_career {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--btn-secondary-bg);
  }
  .scroll_down {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    flex-direction: column;
    margin-top: 3.5rem;
    color: var(--text-gray-800);
    cursor: pointer;
  }
  .recommended_school {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: var(--dark-blue) !important;
  }
  .recommended_school_p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-dark-grey);
  }
  .result_page_button {
    margin-top: 1rem;
    margin-left: 9rem;
  }
  .result_page_button .btn {
    background: var(--primary);
    border: 1px solid var(--primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-white);
  }

  .result_page_general p {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .text-left {
    margin-right: -5rem;
    margin-top: -1rem;
  }
  .more__details {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
  }
  .more__details a {
    text-decoration-line: underline;
    color: var(--primary);
  }
  
  .pagination__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pagination {
    font-family: Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--btn-secondary-bg);
    padding: 0.2rem 0.5rem;
    cursor: pointer;
  }
  
  .active_page {
    background: var(--primary);
    color: var(--text-white);
    border-radius: 4px;
  }
  .pagination:nth-child(n + 2) {
    margin-left: 1rem;
  }
  .pages_count {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-blue);
  }
  
  .next_btn {
    font-family: Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary);
    cursor: pointer;
  }
  
  .courses_list {
    padding: 0.2rem .5rem;
    font-size: 16px;
    width: 100%;
  }
  
  .courses_list:hover {
    cursor: pointer;
    color: var(--primary);
  
  }
  .share {
    width: 138px;
    height: 47px;
    background: #F3F3F3;
    border-radius: 4px;
    border: none;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  
    /* Grey */
  
    color: #4D5E70;
  }
  
  .share_post {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4rem;
  }
  
  .college_info  {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  
  .share_icon {
    color: var(--primary);
  }
  
  .info_left {
    text-align: left;
  }
  
  .info_left .heading {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */
    /* Black */
  
    color: #011A32;
  }
  
  .info_left .address {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;  
    color: #4D5E70;
  }
  
  .info_left .fax_officer_label {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    
    color: #011A32;
  }
  
  .info_left .fax_officer {
      text-decoration: underline;
      font-size: 16px;
      line-height: 140%;
      color: #4D5E70;
  }
  .info_right {
    text-align: right;
    margin-top: -2rem;
  }
  
  .info_right .post_date {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    
    text-align: right;
    
    /* Black */
    
    color: #011A32;
  }
  
  
  .post_affiliate {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
  
    text-align: right;
  
    /* Grey */
  
    color: #4D5E70;
  }
  
  .post_affiliate .answer {
      color: var(--primary);
      font-weight: bold;
  }
  
  .applicant_numbers {
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 5px;
  
    text-align: center;
  
    color: #000000;
  }
  
  
  .user_images {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .user_imageOne {
       z-index: 4;
       margin-right: -15px;
  }
  
  .user_imageTwo {
       z-index: 2;
  }
  
  .user_imageThree {
     z-index: 1;
     margin-left: -15px;
  
  }
  .college_gallery {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 2rem auto
  }
  .college_basic_info {
      display: grid;
      grid-template-columns: auto auto auto auto;
      margin: 4rem auto
  }
  
  .basic_info {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    padding: 20px;
  }
  
  .p-text-primary {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    
    /* Primary Colour */
    
    color: var(--primary)
  }
  
  .basic_info_details {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  
    /* Black */
  
    color: #011A32;
  }
  
  .basic_info_details .basic_price {
      color: rgb(88, 84, 84);
      font-style: normal;
      font-weight: normal;
      margin-left: 4px;
  }
  
  .images_scroll h5 {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
  
    color: #011A32;
  }
  
  .application_info {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    
    /* Black */
    
    color: #011A32;
  }
  
  .application_info_text {
      width: 587px;
      height: 150px;
      left: 83px;
      top: 1481px;
      
      font-family: Samo Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      /* or 26px */
      
      
      /* Grey */
      
      color: #4D5E70;
  }
  
  .first_deadline {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    
    
    /* grey */
    
    color: #4D5E70;
  }
  
  .application_date {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    
    color: #011A32;
    margin-left: 2rem;
  }
  
  .offered_courses_header  {
      margin-top: 3rem;
  }
  .offered_courses_header h5 {
    width: 219px;
    height: 20px;  
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 1rem;
    
    /* Black */
    
    color: #011A32;
  }
  .offered_courses_header ul li {
      list-style: none;
      width: 301px;
      font-family: Samo Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      color: #4D5E70;
  }
  
  
  .offered_courses_header ul .list_one {
    margin-left: -2rem;
  }
  
  
  .website_link h5 {
    width: 219px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
  
    margin-top: 3rem;
    
    color: #011A32;
  }
  
  .website_link a {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: #0480FC;
    margin-top: 4rem;
  }
  .button__primary {
    display: block;
    width: 170px;
    margin-top: 1rem;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
  }
  /* Responsiveness */
  @media screen and (min-width: 48em) {
    .college_simulator_content {
      padding: 5rem 0 0 0;
    }
    .button_getstarted_go span {
      margin-left: 20px;
    }
  
    .image_part img {
      margin-top: -7rem;
    }
  
    .college_simulator_content p {
      width: 64%;
    }
  
    .college_simulator_content h2 {
      line-height: 50px;
    }
    .name_link h3 {
      color: var(--primary) !important;
      font-weight: 700;
    }
  
    .simulator_wrapper {
      padding: 2.5rem 3.5rem 0 3.5rem;
    }
    .choices_wrapper {
      padding: 2.5rem 3.5rem 4.5rem 3.5rem;
    }
  
    .colleges_wrapper {
      padding: 2.5rem 3.5rem 4.5rem 3.5rem;
    }
    .name_link h3 {
      font-size: initial;
      margin-top: initial;
    }
  
    .back_link {
      font-size: initial;
    }
    .form-select,
    .city-form {
      width: 70%;
    }
    .questions_tag {
      width: 70%;
      text-align: center;
      font-size: 24px;
    }
    .choice_form1 h2 {
      font-weight: normal;
      text-align: center;
      width: 70%;
    }
    ul.list_tags {
      width: 70%;
    }
  
    .username_message {
      font-size: 36px;
    }
  
    h2.success_message {
      font-size: 64px;
    }
    .success_note {
      width: 40%;
      margin: auto;
    }
    .success_note_career {
      width: 50%;
      margin: auto;
    }
    .cta-links {
      width: 70%;
    }
    .cta-links {
      font-size: initial;
    }
  }

  .paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    background-color: #0480FC;
    font-weight: bold;
    color: #ffffff;
    border: 1px solid #0480FC;
    width: 100%;
    height: 25px;
  }

  .school_logos {
    width: 150px;
    height: 80px;
 }
  
  @media screen and (min-width: 64em) {
    ul.list_tags {
      width: 60%;
    }
  
    .form-select,
    .city-form {
      width: 43%;
    }
    .questions_tag {
      width: 43%;
      text-align: center;
      font-size: 24px;
    }
    .choice_form1 h2 {
      font-weight: normal;
      text-align: center;
      width: 43%;
    }
  }

  @media screen and (max-width: 996px) {
    .school_logos {
       width: 120px;
       height: 60px;
    }
 }

  @media screen and (max-width: 480px) {
     .school_logos {
        width: 80px;
        height: 40px;
     }

    .next_question {
      font-size: 10px;
    }

    .btnNext button {
      width: 95px;
      height: 45px;
      border: none;
      background: var(--primary);
      color: var(--text-white);
      border-radius: 10px;
    }
  }

  

  