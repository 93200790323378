.secondary_nav_profile {
    width: 99%;
    margin: auto;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .view-simulation-btn  {
    margin-left: 4rem !important;
} */

.view-simulation-btn button {
    background: var(--primary);
    backdrop-filter: blur(24px);
    border-radius: 8px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: .5rem 1rem;
    border: 1px solid var(--primary);

    color: var(--text-white)
}

.profile_details_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card_personal_info_counsellor {
    width: 100%;
    margin: 2.5rem auto;
    background: #FFFFFF;
    /* border: 1px solid #DFE0EB; */
    box-sizing: border-box;
    border-radius: 8px;
}

.card_header {
    padding: 1rem 0 .5rem 2rem;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #000000;
    
}

.view_documents {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary)
}


.parent_view_more a {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;    
    color: #011A32;
}

.counsellors_details_row {
  margin-bottom: 2rem;
}

.parent_career_card {
background: var(--bg-white);
border-radius: 12px;
position: relative;
}
.parent_career_simulate {
width: 100%;
padding: 2rem 1rem;
}
.parent_career_title{
font-family: Samo Sans Pro;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: var(--primary);
}

.parent_result_header {
font-family: Samo Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: var(--btn-secondary-bg);
}

.parent_realistic_header {
font-family: Samo Sans Pro;
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 60px;
color: var(--success)
}

.parent_career_text {
font-family: Samo Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: var(--btn-secondary-bg);
width: 60%;
}

.parent_career_recommendations {
font-family: Samo Sans Pro;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 140%;
text-decoration-line: underline;
color: var(--primary);
text-decoration: underline;
border: none;
outline: none;
background: none;
}

.counsellor_bg_career_img{
    height: 180px;
    width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.parent_career_bag {
position: absolute;;
width: 80px;
height: 80px;
background: #e8f5ff;
border-radius: 50%;
right: 15%;
bottom: 24%;
}

.parent_career_bag img {
width: 100%;
height: 100%;
}

.country_header {
font-family: Samo Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 12.2357px;
line-height: 15px;
color: #333333;

}

.country_detail {
width: 70%;
font-family: Samo Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;

/* Gray 1 */

color: #333333;
}

.parent_page  {
width: 98%;
}