.applicants_info_style {
    width: 100% !important;
    height: auto;
    margin: 2rem auto;
    border-radius: 12px;
  }

  .card-headers {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
.student-header {
  width: 100%;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: var(--primary);
}
.divider {
  border-top: 1px solid #EEEEEE;
  padding-bottom: 1rem;
}
.student_input_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.student_input {
    width: 84%;
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.student_input input {
    width: 88%;
  
}

.sort_area {
  width: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort_area .fa-sort-icon {
  color : #C5C7CD;
}
.sort_area .sort-text {
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;  
  color: #4B506D;
  
}

.search-field {
  border: none !important; 
  border-bottom: 0.7px solid #C5C7CD !important;
  color: rgb(18, 21, 32) !important;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.2px;
}
.search-field:focus {
  outline: none !important;
} 

.search-icon {
  margin-right: 15px !important;

}

.search-icon .fa-search-icon {
  color:#C5C7CD;
  box-sizing: border-box;
}
.table_body,  th, td, tr {
  padding: 1rem 0 !important;
}
.application_status span{
  padding: 5px 12px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;

  color: var(--text-white);
  
  background: #F2994A !important;  
  border-radius: 8px;
}

.simulation_status span {
  padding: 5px 12px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
   cursor: pointer;
  color: var(--text-white);
  
  background: #2F80ED !important;  
  border-radius: 8px;
}

.view_status span {
  padding: 5px 12px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;

  color: var(--text-white);
  
  background: #F2994A !important;  
  border-radius: 8px;
}

.profile_status button {
  padding: 5px 12px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: 8px;
}

.message_status span {
  padding: 5px 12px;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;

  color: var(--text-white);
  
  background: #6FCF97 !important;  
  border-radius: 8px;
}

.paginate-page {
    width: 100%;
    display: flex;
    margin: 1rem auto;
    justify-content: space-between;
    align-items: center;
}

.paginate-page .page-shown {
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  
  color: #011A32;
}
.paginate-page .next-page {
  display: flex;
  align-items: center;
  font-family: Samo Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  cursor: pointer;
}

.paginate-page .page-numbering {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.paginate-page .page-numbering span:nth-child(n+2) {
    margin-left: 1rem;

}
.paginate-page .page-numbering .page-active {
  background: var(--primary);
  padding: 2px 8px;
  border-radius: 4px;
  color: #FFFFFF;
}

  @media screen and (min-width: 48rem) {
    .card-headers {
      width: 100% !important;
      flex-direction: initial;
      justify-content: space-between;
    }
    .student-header {
      width: 60%;
    }
    .student_input_area {
       width: 40%;
    }
  }