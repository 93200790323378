.tabs__container ul{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tabs_link  {
    list-style: none;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    cursor: pointer;
    color: #828990;
 }
 .tabs_link:hover {
    color: #25252D;
    transition: all .5s ease-in-out;
 }

 .active_tabs {
    position: relative;
    font-weight: bold;
    color: var(--primary);
    transition: all .3s ease-in-out;

}
.active_tabs::after {
  position: absolute;
  bottom: -.3rem;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--primary);
  transition: all .3s ease-in-out;

}


.personal_profile_form, .Educational_profile_form {
      width: 94% !important;
      margin-left: 2rem !important;
}


.personal_profile_form .input, .Educational_profile_form input {
    width: 454px;
    height: 48px;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #828990;
}
.personal_profile_form .ant-select-selector, .Educational_profile_form .ant-select-selector {
    padding: 1.47rem 2rem !important;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
   
}

.personal_profile_form .ant-select, .Educational_profile_form .ant-select {
    width: 454px !important;
    height: 48px !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #828990;
    border-radius: 4px !important;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #828990;
}

.personal_profile_form .ant-select-single .ant-select-selector .ant-select-selection-item , .Educational_profile_form .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: absolute !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -1rem !important;
    margin-left: -1rem !important;
}

.personal_profile_form label, .Educational_profile_form label {
    width: 100%;
    height: 14px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin: 10px 0;
    color: #000000;
}

.required_field{
    color: red !important;
}

.anticon .anticon-calendar {
      color: #000000 !important;
}

.personal_button, .update_button {
    background: #0480FC !important;
    border-radius: 4px !important;
    height: 42px !important;
    color: var(--text-white) !important;
    width: 150px !important;
    text-transform: initial !important;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
}


/** Educational Profile */

.Educational_profile_form {
    height: auto;
    padding-bottom: 5rem;
}

.Educational_profile_form .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
    height: auto !important;
}

.Educational_profile_form .ant-select-selection-overflow-item {
    margin-top: -2rem !important;
}
.css-1okebmr-indicatorSeparator {
    display: none !important;
}
.css-2b097c-container {
    width: 454px !important;
    min-height: 48px !important;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #828990;
    border-radius: 4px !important;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #828990;  
}

.css-1rhbuit-multiValue {
    background-color: #828990 !important;
    border-radius: 4px !important;
    padding: .3rem 0 !important;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff !important;
}

.css-12jo7m5 {
    color: #ffffff !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
} 

.css-tj5bde-Svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}

.card_add_info {
    margin-top: 2rem;
    margin-left: .7rem;
    width: 233px;
    height: 39px;
    background: rgba(245, 245, 245, 0.4);
    border-radius: 20px;
    display: flex;
    align-items: center;
  
}

.card_add_info span:first-child {
    padding: .5rem .8rem;
    background: #ffffff;
    color: var(--primary);
    border-radius: 50%;
    cursor: pointer;
}

.card_add_info span:nth-child(n+2){
    color: var(--primary);
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin-left: .7rem;
}

p.new_document_placeholder {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;    
    color: #4D5E70;
    margin-left: 1rem;
    margin-top: 1rem;
}