.card-style {
  background: #004583;
}

.card-style span{
  list-style-type: none;
  border-radius: 20px;
  font-weight: 900;
  font-size: 20px;
}

.card-style button{
  color: black;
  text-transform: uppercase;
  background: inherit;
  border: none;
  font-weight: 700;
  font-size: 20px;
  /* border-bottom: 1px dashed white; */
}

.card-style button:hover{
   background-color: white;
   color: #0080ff !important;
   transition: 0.9s;
   border-radius: 10px;
   padding: 8px;
}

.speak_to_counsellor{
   background-color: #004583;
}

.btns{
  color: black;
  text-transform: uppercase;
  background: inherit;
  border: none;
  font-weight: 700;
  font-size: 20px;
  /* border-bottom: 1px dashed white; */
}

/* .btns:hover{
   background-color: white;
   color: #0080ff !important;
   transition: 0.9s;
   border-radius: 10px;
   padding: 8px;
} */
