@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("../src/swiper.css");
:root {
  --primary: #0480fc;
  --success: #27ae60;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --bg-color: #F7FBFF;
  --bg-color-2: #f0f2f5;
  --accent-color-button: #f4f7fc;
  --accent-color-text: #fbfbfb;
  --accent-color-text-not-selected: #828282;
  --danger-background-100: #f39a9a;
  --text-dark: #000000;
  --bg-dark: #000000;
  --btn-secondary-bg: #333333;
  --text-grey: #4d5e70;
  --btn-default-bg: #ffffff;
  --bg-icons: #d6f1f5;
  --color-dark-600: #011a32;
  --text-color-default: #f5f5f5;
  --text-gray-800: #76787a;
}

html,
body,
* {
  font-family: "Source Sans Pro", sans-serif !important;

}


html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
 
}

.header_nav {
     display: flex;
     justify-content: space-between !important;
     align-items: center;
     width: 100%;
     margin-top: 20px;
}

.form-select {
  background-color: #fff;
}

.ant-layout {
  background: var(--bg-color) !important;
  width: 100% !important;
}

.ant-layout-sider {
  background: var(--text-white) !important;
}

.ant-layout-header {
  background: var(--bg-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem !important;
  top: 0;
  margin: 0 0px 35px 30px;
  bottom: 0;
  width: 80%;
  /* position: fixed;
  /* z-index: 10000; */
  /* box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1); */
  margin-left: 20px;
}

.ant-layout-header-career {
  background: var(--bg-color-2) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem !important;
  margin-top: 1rem;
}

.ant-layout-sider-zero-width-trigger {
  background: var(--primary) !important;
}

.site-layout-background {
  background: var(--bg-color) !important;
}

.ant-design-guide {
     background: var(--bg-color-2) !important;
}
.site-layout-background-career {
  background: var(--bg-color-2) !important;
}
.logo-icons {
  line-height: 0.7;
  padding-left: 10px;
}
.logo-icons img {
  width: 10px;
  height: 10px;
}

.dashboard_logo {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
}

.dashboard_identity {
  /* padding-top: 2rem; */
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.company_icon_logo {
  width: 100px;
  margin-left: 8px;
  margin-bottom: -5px;
}

.progress_none {
  display: none;
}

.ant-menu-title-content a {
  color: var(--accent-color-text-not-selected) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--primary) !important;
  border-radius: 8px;
  padding: 1rem;
  color: var(--accent-color-text) !important;
}

.ant-menu-item-selected a {
  color: var(--accent-color-text) !important;
}

.ant-menu-item {
  width: 90% !important;
  height: 60px;
  margin: auto !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 500 !important;
  color: var(--accent-color-text-not-selected) !important;
}


.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: var(--bg-color) !important;
}

.ant-layout-footer-career {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background:  var(--bg-color-2) !important;
}

.logout {
  background: var(--danger-background-100) !important;
  width: 90%;
  padding: 0.5rem 1rem;
  color: var(--accent-color-button);
  position: relative;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

.user_details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 1.7rem;
}

.username {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 700;
  color: var(--primary);
  font-size: 20px;
  margin-left: 1.7rem;
}
.more_details {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  height: 40px;
  width: 160px;
  color: var(--text-dark);
  background: var(--bg-white);
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.user_details .icons {
  color: 1.5px solid #200e32;
  margin-right: 2rem;
  font-size: 20px;
  cursor: pointer;
}
.profile_pics {
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  padding: 3px;
  margin-left: 0.3rem;
}

.user_name {
  color: var(--text-dark);
  font-weight: 400;
  font-size: 11px !important;
  letter-spacing: -0.02em;
}
.user_role {
  color: var(--text-dark);
  font-size: 10px;
  font-weight: 400;
  margin-left: 10px;
  text-transform: capitalize;
}

.name_role {
  margin-right: 1rem;
  line-height: 0.8;
  font-family: "Source Sans Pro", sans-serif !important;
}

.new_jumbotron {
  width: 100% !important;
  height: auto;
  padding: 2rem;
  background: var(--primary);
  border-radius: 12px;
  font-family: "Source Sans Pro", sans-serif !important;
}
.new_jumbotron h2 {
  font-weight: 700;
  color: var(--text-white);
  font-size: 30px;
}
.new_jumbotron p {
  font-weight: 400;
  color: var(--text-white);
  font-size: 16px;
  padding: 2rem auto;
}

.btn-application {
  font-size: 18px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif !important;
  background: var(--btn-default-bg) !important;
  color: var(--primary);
  border: none !important;
  outline: none !important;
}

.btn-complete {
  font-size: 18px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif !important;
  background: var(--btn-secondary-bg) !important;
  color: var(--text-white);
  border: none !important;
  outline: none !important;
  
}
.btn-application:hover {
  color: var(--primary);
}

.new_user img {
  width: 100%;
  max-height: 300px;
  height: 270px;
}

.essential_new_user {
  background: var(--bg-white);
  padding: 1rem;
  border-radius: 12px;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif !important;
}

.essential {
  border: 1px solid var(--bg-icons);
  background: var(--bg-icons);
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.essential_new_user h3 {
  font-weight: bold;
  color: var(--text-dark);
  font-size: 32px;
}


.essential_new_user p {
  font-weight: 400;
  font-size: 18px;
  color: var(--text-grey);
}
.essential_new_user:nth-child(n + 2) {
  margin-top: 1.5rem;
  margin-left: .5rem !important;
}



.get_started_link {
  font-weight: 700;
  font-size: 16px;
  color: var(--primary);
  border: none !important;
  background: transparent !important;
}
.graduate {
  font-family: "Source Sans Pro";
  display: flex;
  align-items: center;
}

.graduate h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: -3px;
  margin-left: 5px;
}

p.text-desc {
  display: flex;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 400;
  color: var(--text-grey);
}
img.grad {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background: var(--bg-icons);
  border-radius: 50%;
  padding: 0.5rem;
}

.start_application {
  color: var(--color-dark-600);
  display: flex;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
}

.blog_user_snippet {
  width: 100%;
  margin: 2rem auto .5rem auto;
  height: auto;
  background: var(--color-dark-600);
  padding: 2rem;
  border-radius: 8px
}

.blog_excerpts {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 700;
  color: var(--text-color-default);
  width: 100%;
  font-size: 28px;
}

.readmore {
  color: var(--text-white);
  text-decoration: underline;
}
.readmore:hover {
  color: var(--text-white);
  text-decoration: underline;
}

.saly_img {
  height: 80%;
}

.simulate-app {
    width: 100%;
    margin-left: 0;
} 
.my__simulation_result {
     width: 100%;
     margin: auto;
}
.start_college_overview {
    margin-left: 1rem !important;
}

.container-dashboard {
  width: 100% !important;
  margin-top: 2rem;
}

.career_simulate_college {
  width: 100%;
  margin: auto;
}

.career_simulate_dashboard {
     width: 100% !important;
     display: flex;
     justify-content: space-between;
     margin-top: 2rem;
     margin-bottom: 1.5rem;
     margin-right: auto;
     margin-left: -5px;
}

.start_career {
    margin-left: 1rem !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  }

.spinner-border {
   margin-left: auto;
   margin-right: auto;
}

.back_link {
    color: var(--primary)
}
.recommended_college_result_wrapper {
    height: 100%;
    width: 100%;
    background: #ffffff !important;
}
.recommended__college {
  background: #ffffff !important;
}
/* Responsiveness from tablet upward */
@media screen and (min-width: 48em) {
  .user_name {
    font-size: 14px !important;
    margin-left: 9px;
  }
  .more_details {
    width: 180px;
  }

  .blog_excerpts {
    font-size: 36px;
    width: 90%;
  }
  .username {
    font-size: 36px !important;
  }
  .blog_user_snippet {
    height: 260px;
    width: 100%;
  }
  .new_jumbotron h2 {
    font-size: 42px;
  }
  .new_jumbotron p {
    font-size: 18px;
  }
  .btn-complete,
  .btn-application {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
  }
  /* .btn-complete {
    margin-left: 2rem;
  } */
  .essential_new_user {
    width: 47%;
  }
  .essential_new_user:nth-child(n + 2) {
    margin-left: 2rem;
    margin-top: initial;
  }
  .start_college {
    margin-left: 5px !important;
  }
}

@media screen and (min-width: 65em) {
  .essential_new_user {
    width: 48.5%;
  }
}

@media screen and (max-width: 575px) {
  .user_name {
   color: none;
  }

  .user_details {
    /* display: flex;
    justify-content: space-evenly; */
    /* align-items: center; */
    /* margin-right: 1.7rem; */
    bottom: 0;
    left: 0;
  }
}




