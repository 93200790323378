.simulator_info {
    margin: 1.6rem 0;
    width: 100%;
}
.simulators_card {
    border-radius: 12px;
}

.card_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: var(--primary);
}

.simulate_details_college {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4D5E70;
}

.simulate_details_college .view_results {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary) !important;
}

@media screen and (min-width: 48em) {
    .simulator_info {
        width: 80%;
    }   
}