.swiper-container {
    width: 100%;
    height: auto;
  }
  
  .swiper-slide {
    font-size: 18px;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    min-height: 200px;
    height: 200px;
  
    /* Center slide text vertically */
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--swiper-navigation-color, var(--swiper-theme-color)); */
    color: rgb(129, 129, 129) !important;
    border-radius: 50%;
  }
  