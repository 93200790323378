.username {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 700;
    color: var(--primary);
    font-size: 20px;
    margin-left: 1.7rem;
  }
  .user_name {
    color: var(--text-dark);
    font-weight: 400;
    font-size: 11px !important;
    letter-spacing: -0.02em;
  }

  .container-width{
      width: 97%;
      margin: auto;
  }

  .row_alignment {
      margin: 2rem auto;
  }
  .application_details {
    height: 126px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
  }
.student_add_section {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
  .detail_no {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
    margin: 0 0 0 5px;
    
    color: #2C2C2C;
  }

  .application_info {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 10px 0px;
    color: var(--primary);
  }


  .simulators_details {
      border-radius: 12px;
  }

  .simulator_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: var(--text-dark);
  }


  .total_simulation {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: #2C2C2C;
  }

  .column_details {
      width: 100px;
      height: 100px;
      margin-left: 40px !important;
  }

  .img_simulate {
      width: 100%;
      background: var(--bg-icons) !important;
      border-radius: 50%;
  }

  .simulator_detail_desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      padding: 1rem;
      background: #F9FDFF !important;
      border-radius: 8px;
      margin-right: 20px !important;
      margin: 6px auto;
  }

  .name_desc {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-dark);
  }

  .view_more_desc {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary);
    cursor: pointer;
  }

  .applicant_table {
    width: 100% !important;
    height: auto;
    margin: 2rem auto;
    border-radius: 12px;
  }


  .applicant_table .card__title span:first-child {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: var(--primary);
  }





  /* tbody, td, th, tr {
    padding: 1rem 0 !important;
} */
  /* Responsiveness from tablet upward */
@media screen and (min-width: 48em) {
    .username {
        font-size: 36px !important;
      }
      .user_name {
        font-size: 13px !important;
        margin-left: 9px;
      }

    
}