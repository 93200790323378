
.card {
    border: none;
    border-radius: 10px
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

/* .icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px
} */
.icon .logo{
    width: 50px;
    height: 50px;
}

.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

.paid span {
  background-color: #108f0479;
  width: 60px;
  height: 25px;
  padding-bottom: 3px;
  border-radius: 5px;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center
}

.study_level span{
  background-color: #108f0479;
   border-radius: 5px;
   color: #ffffff;
}

.view_result{
   background-color: #0480FC;
  color: #ffffff;
}


.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0;
    cursor: pointer
}

.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
    /* float: right;
    bottom: 60px */
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    /* letter-spacing: 2px; */
    outline: none;
    border-radius: 50%;
    transition: all .5s ease-in-out;
    background-color: #0480FC;
    padding-right: 40px;
    color:#000000;
  }
  .input-search::placeholder{
    color:rgba(0, 0, 0, 0.5);
    font-size: 18px;
    /* letter-spacing: 2px; */
    font-weight: 800;
  }
  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 15px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color: #ffffff;
    background-color:transparent;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid #0480FC;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid #0480FC;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }


  .card {
    position: relative;
    transition: all .5s ease-in;
  }  

  .card__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: none;
    transition: .5s ease;
    background-color: rgba(4, 128, 252, 0.842);
    -webkit-box-shadow: -2px -1px 15px 7px rgba(0,0,0,0.5);
    -moz-box-shadow: -3px -2px 30px 14px rgba(0,0,0,0.425);
    box-shadow: -4px -3px 10px 2px rgba(0,0,0,0.35);
  }
  
  .card:hover .card__overlay {
    opacity: 1;
  }
  
  .overlay__text {
    color: white;
    /* font-size: 20px; */
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .overlay__text button{ 
        border: none;
        background-color: rgba(2, 55, 109, 0.842);
  }