.register_container {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
  }
  
  .banner h1, .banner p {
    display: none;
  }
  .password {
      border-radius: 8px !important;
  }
  
  .register {
      width: 80%;
      margin: 5rem auto auto auto;
  }
  .category_container {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .category_container h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #0480fc;
  }
  .category_section  {
      margin-top: 2rem;
      width: 100%;
  }
  
  
  .grid {
    height: 100px;
    border-radius: 8px;
    max-width: 145px;
    width: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    color: #d3d3d3;
    border: 1px solid #d3d3d3;
    transition: .4s ease-in;
    cursor: pointer;
  }
  .active_grid {
    border: 1px dashed #0480fc;
    color: #0480fc;
    transition: .4s ease-in;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }
  .messages {
      width: 76%;
      height: auto;
      padding: 1.5rem;
      text-align: center;
      color: #fafafa;
      margin-bottom: 1rem;
  }
  
  .errors {
      background: rgb(199, 64, 64);
  
  }
  
  .success {
    background: rgb(35, 121, 35);
  }
  
  
  .ant-input {
    padding: 1rem 1.2rem !important;
    border-radius: 8px !important;
  }
  .ant-select-selector {
      padding: 2rem 1.2rem !important;
      border-radius: 8px !important;
  }
  .ant-btn {
    width: 100%;
    height: 60px;
    background: #0480fc !important;
    border-radius: 8px !important;
    text-transform: uppercase;
    font-weight: bolder;
  }
  .check_school {
      position: relative;
  }
  .check_school label {
       padding: 0 .7rem;
       background: #fff;
       font-weight: 700;
       position: absolute;
       color: rgb(109, 105, 105);
       left: .7rem;
       top: -.7rem;
  }
  .select_input {
    width: 100%;
    padding: 1.5rem 3rem 1.5rem 1.5rem !important;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 8px;
    outline: none;
  }
  
  .select_input:active, .select_input:focus {
    border: 1px solid #d9d9d9;
    outline: none;
  }

  .trav4College_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
  }
  .register_description {
      position: relative;
      top: 30%;
      width: 90%;
      height: 100%;
      padding: 3rem;
      margin: auto;

  }

  .register_description h1 {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
  }

  .register_description p {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 142.5%;
  }
  .account_login_link {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #011A32;
    width: 90%;
    margin-left: 10rem;
    margin-bottom: 45px;
  }
  .login_link {
    color: #1890ff;;
  }
  .admin_login {
      position: absolute;
      top: 1rem;
      right: 2rem;
      font-family: Samo Sans Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: right;
      color: #075CB2;
  }
  @media screen and (min-width: 768px){
  .banner {
     width: 45%;
     height: 100%;
     position: fixed;
     background: url("../../assets/graduates.png") no-repeat center center; 
     background-position: top 0% right;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     color: #fff;
     font-weight: normal;
  }
  
  .banner h1 {
      font-weight: 700;
      font-size: 4rem;
      margin-bottom: -1rem;
      color: #fff;
      display: block;
  }
  
  .banner p {
    font-size: 1.5rem;
    font-weight: 400;
    display: block;
    margin-top: 2rem;
   }  
    .register {
       position: absolute;
       width: 55%;
       left: 50%;
       margin-top: 5rem;
    }
   .first_name .last_name {
        display: inline-block;
        width: 50%;
     }

    .names .full_name{
      display: inline-block;
      width: 43%;
      /* margin: 0;
    padding: 0;  */
    }   
  
   .last_name {
       margin-left: 0px;
    }
  
    .category_container {
      width: 100%;
    }
    
    .grid {
      width: 120px;
     
    }

    .ant-input {
        padding: 1.2rem !important;
      }
      .ant-select-selector  {
          padding: 2.3rem 1.2rem !important;
    
      }
      .ant-btn {
        width: 100%;
      }
      .select_input {
          padding: 2rem 1.5rem;
      }
    
  }
  
  @media screen and (min-width: 1024px){
    .first_name, .last_name {
      display: inline-block;
      width: 48%;
   }
  
  .last_name {
     margin-left: -60px;
  }
  
  
    .category_container {
      width: 100%;
    }
  
    .grid {
      width: 150px;
    
    } 
    .single_grid {
      margin-left: -2.5rem;
    }
    .single_grid2{
      margin-left: -5rem;
    }
  
  
  }
  