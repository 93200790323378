.career_card {
    background: var(--bg-white);
    border: none;
    border-radius: 12px;
    position: relative;
}
.career_simualte {
    width: 100%;
    padding: 2rem 1rem;
}
.career_title {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--primary);
}

.result_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--btn-secondary-bg);
}

.table_body,  th, td, tr {
    padding: 1rem 0 !important;
  }

.message_status span {
    padding: 5px 12px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    color: var(--text-white);
    background: #2F80ED !important;  
    border-radius: 8px;
  }  

.realistic_header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: var(--success)
}

.career_text {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--btn-secondary-bg);
    width: 60%;
}

.career_recommendations {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: var(--primary);
    text-decoration: underline;
    z-index: 9999999 !important;
    position: relative;
}

.bg_career_img{
    height: 200px;
    width: 55%;
    position: absolute;
    bottom: 1px;
    right: 0;
}

.career_bag {
    position: absolute;;
    width: 80px;
    height: 80px;
    background: #e8f5ff;
    border-radius: 50%;
    right: 20%;
    bottom: 25%;
}

.career_bag img {
    width: 100%;
    height: 100%;
}

.restart_simulation {
    background: var(--primary);
    margin-left: 1rem;
    padding: 1rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
}


.simulation_text {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.02em;
    color: var(--text-white);
}

.path_arrow {
      margin-left: 4rem;
      padding: 1rem;
      background: var(--bg-white);
      border-radius: 50%;
}

.path_arrow {
     width: 100%;
     height: 100%;
     text-align: center;
     color: var(--primary);

}



.career_simulator_content {
    padding: 5rem 0 0 0;
    width: 100%;
    height: 100vh;
  }
  
  .career_simulator_content h2 {
    line-height: 40px;
    font-weight: 700;
    color: var(--primary);
    letter-spacing: 4px;
  }
  
  .career_simulator_content p {
    font-weight: 400;
    font-size: 18px;
    color: var(--bg-dark);
    width: 80%;
  }
  .career_simulator_content a {
    background: var(--color-dark-600);
    outline: none;
    border: 1px solid var(--color-dark-600);
    color: var(--text-white);
    padding: 0.5rem 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    border-radius: 8px;
  }
  
  .image_part_career img {
    width: 100%;
    height: 100%;
    margin-top: -1rem;
  }


  
.card__header {
    font-size: 16px;
    line-height: 20px;
    padding: .5rem 2.5rem;
    letter-spacing: 1.25px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #0480FC;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #F7FBFF;
    border-bottom: 1px solid var(--alpha-40);
}

.all_questions {
       display: flex;
       justify-content: space-between;
       font-family: Samo Sans Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 50px;
        color: #000000;
        margin: 0px 30px;
       
} 


.question_options {
    display: flex;
    justify-content: center;
    align-items: center;
}