.NotFound{
    padding: 0;
    width: 100%;
    margin: 60px auto;
}

.image_design{
    margin: 10em;
}

.img_div{
    animation-name: imageAnimate;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    /* animation-direction: reverse;   */
}
/* .image_design .text_design{
  line-height: 60px;
} */

.text_design .header{
    font-weight: bold;
    font-size: 7em;
    color: #0480FC;
    animation-name: imageAnimate;
    /* animation-duration: 4s; */
    animation-iteration-count: infinite;
}

.text_design .semi_header{
    font-weight: bold;
}

.button_home{
    width: 100px;
    padding: 4px;
    background-color: #0480FC;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 20px;
    margin-top: 35px;
    text-align: center;
}

button:active{
    transform: translateY(3px);
}

@keyframes imageAnimate {
    0%   {left:5px; top:10px;}
    25%  {left:10px; top:0px;}
}