.help_desk {
    margin-left: 1.5rem;
    height: auto;
    padding-bottom: 6rem;
}

.form-subject input {
    background: #FFFFFF !important;
    border: 1px solid #A0A0A0 !important;
    border-radius: 10px !important;
    width: 100% !important;
    outline: none !important; 
}
.form textarea{
    background: #FFFFFF !important;
    border: 1px solid #A0A0A0 !important;
    border-radius: 10px !important;
    width: 100% !important;
    outline: none !important;  
    padding: 1rem;
}
.form-subject input:focus  {
    outline: none !important; 
    border-color: var(--primary) !important;
}

.form-subject label, .form label {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 138%;
    color: var(--text-dark);
}
.form textarea:focus {
    outline: none !important; 
    border-color: var(--primary) !important;
}
.helpdesk_button {
    border-radius: 10px;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: .5rem 1.5rem;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--text-white);
}

.helpdesk_details {
    margin-top: 3rem;
}

.helpdesk_contact {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-dark);
}

.helpdesk_important_details {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: var(--text-dark);
}

@media screen and (min-width: 48em) {
    .form-subject input {
        width: 50% !important;
    }    
    .form textarea {
        width: 50% !important;
    }
}