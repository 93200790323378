:root {
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --weight3: 600;
}

.box p {
    color: var(--grayishBlue);
}

.box{
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 2px;
}

.scholar_img{
    float: right;
    width: 80px;
    height: 80px;
}

.orange {
    border-top: 3px solid var(--blue) !important;
}

h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}


.btn {
    background-color: #0480FC;
    border-radius: 4px;
    color: aliceblue;
}

h3 {
    margin: 10px 0;
}
/* 
h6 {
    margin: 5px 0;
	text-transform: uppercase;
}

p {
    font-size: 14px;
	line-height: 21px;
} */

.card-container {
    background-color: rgb(4, 128, 252);
	border-radius: 5px;
	box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
	color: #B3B8CD;
	padding-top: 30px;
	position: relative;
	text-align: center;
}

.card-container .pro {
    color: #0480FC;
	background-color: #FEBB0B;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;
	position: absolute;
	top: 30px;
	left: 30px;
}

.card-container .round {
    border: 1px solid #03BFCB;
	border-radius: 50%;
	padding: 7px;
}

div.primary {
    background-color: #03BFCB;
	border: 1px solid #03BFCB;
	border-radius: 3px;
	color: #231E39;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	padding: 10px 25px;
}

div.primary.ghost {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
    color: rgb(255, 255, 255);
}

.paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    background-color: #0480FC;
    font-weight: bold;
    color: #ffffff;
    border: 1px solid #0480FC;
    width: 100%;
    height: 35px;
}

.bg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
    }

    
.searchBox {
    /* background: #ffffff; */
    height: 40px;
    /* border-radius: 40px; */
    padding: 10px;
    border: none;
    outline: none;
}

.grid-containerss {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}
.hero-star-content {
    font-family: Samo Sans Pro;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
}

.scholarship_card {
   border-radius: 5px;
   padding: 10px;
   box-shadow: -2px 13px 21px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: -2px 13px 21px 0px rgba(0,0,0,0.3);
-moz-box-shadow: -2px 13px 21px 0px rgba(0,0,0,0.3);
}

.scholarship_header_tag{
    font-family: Samo Sans Pro;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}

.scholarship__p{
    font-family: Samo Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
}

.ongoing_scholars {
    font-family: Samo Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #075CB2;

}

.ongoing_scholars_days{
    font-family: Samo Sans Pro;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: right;
color: #4D5E70;

}

.share_facebook {
    background: #EDF1FA;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 5px;
}
.share_instagram {
    background: #F4E6FF;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 5px;
    color: '#5A02A0'
}
.share_twitter {
    background: #E3F6FD;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 5px;
    color: #00ACEE;
}
.share_linkedIn {
    background: #DEF2FC;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 5px;
    color: #0E76A8;
}
@media (min-width: 950px) {
        .row2-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    @media all and (max-width: 779px){
        .imagess {
            display: none;
        }
        .scholar_img{
            display: none;
        }
        .grid-containerss {
            display: grid;
            grid-template-columns: auto auto auto;
            /* padding: 10px; */
        }
        .my-scholarship{
            margin-left: 0%;
        }
    }   

    @media all and (max-width: 452px){
        .grid-containerss {
            display: grid;
            grid-template-columns: auto auto;
            
            /* padding: 10px; */
        }
        .my-scholarship{
            margin-left: 0%;
        }
    }

    @media all and (max-width: 345px){
        .grid-containerss {
            display: grid;
            grid-template-columns: auto;
            /* padding: 10px; */
        }
        .my-scholarship{
            margin-left: 0%;
        }
    }

