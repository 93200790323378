.card-width {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.07);
    border-radius: 12px;
    border: none;
    width: 220px;
}

.card-width span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    letter-spacing: -0.02em;
    color: #000000;

}


.card-width p {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000
}

.simulate-app {
    display: flex;
}
.simulated-application-width {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.simulate-app .completed-application {
    margin-left: 1.2rem;
}

.simulated-result-panel {
    border: none;
    border-radius: 12px;
    margin: 2rem auto;
}

.simulated_result_college {
    margin: 0 !important;
    width: 100%;

}

.simulated-result-header {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--primary);
}
.simulated-career-body {
    padding: 1rem;
}

.all-city-simulated-container {
    width: 70% !important;
    padding: 1rem 0;
}

.all-city-simulated {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
}

.all-city-simulated-header {
    display: block;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.simulator-buttons {
    padding-bottom: 1.7rem;
    display: flex;
}

.simulator-buttons .recommended-btn-simulate  button, .simulator-buttons .restart-btn-simulate button {
    border-radius: 10px;
    padding: .7rem 1rem;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}
.recommended-btn-simulate button{
    background: var(--primary);
    border: 1px solid var(--primary);
    color: #FFFFFF;
}
.restart-btn-simulate button {
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    margin-left: 1rem;
    color: #828282;
}

.recommended-btn-simulate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.simulated-card-placeholder {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 10.4877px;
    line-height: 13px;
    color: #76787A;
    display: block;
}

.simulated-result-panel {
    position: relative;
}

.header__section_simulate {
    display: flex;
    justify-content: space-between;
    border-bottom:  1px solid #EEEEEE;
    align-items: center;
}
.list__schools {
    display: flex;
    margin: .7rem auto;
    align-items: center;
    justify-content: space-between;
}
.school__logo {
    width: 50px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 24px rgba(202, 202, 202, 0.25));
    border-radius: 80px;
}

.img_school_text {
    width: 70%;
    display: flex;
    align-items: center;
}
.img_school_text span {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #011A32;
}

.career__title {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #4D5E70;
}

.career_number_application {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #0480FC;
}

.btn_ongoing {
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    color: #FF922D;
    padding: 5px 12px;
    background: #FFF4EB;
    border-radius: 6px;
    border: 1px solid #FFF4EB;
}

.view_all_application {
    text-align: center;
    font-family: Samo Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #011A32;
    cursor: pointer;
}

.card-swiper {
    width: 300px !important;
    height: 237px;   
    box-shadow: 0px 0px 24px rgba(198, 198, 198, 0.25);
    border-radius: 8px;
    border: none;
}
  

@media screen and (max-width: 480px){
        .card-width {
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.07);
            border-radius: 12px;
            border: none;
            width: 160px;
        }
}
